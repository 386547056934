import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {

  static targets = ["confirmationModal", "reasonSelect", "changeForm", "changesCard", "changesLoader", "verification", "confirmButton", "proceedButton", "noChanges", "verificationDiv", "attestationDiv"];
  static values = {
    sampleId: String,
    context: String,
    stepRecordId: String
  };

  connect(){
    this.toggleSubmitButtons()
    if (this.contextValue == "edit"){
      $('.datepicker').datepicker({
        clearBtn: true,
        format: 'dd-M-yyyy',
        keepEmptyValues: true,
        autoclose: true
      }).on('changeDate', (ev) => {
        this.manageOtherAliquots();
      });
      $('.timepicker').timepicker().on('changeTime.timepicker', this.manageOtherAliquots);
    }
  }

  noChangesTargetConnected(){
    this.confirmButtonTarget.classList.add("d-none")
    this.proceedButtonTarget.classList.remove("d-none")
    if (this.hasVerificationDivTarget){
      this.verificationDivTarget.classList.add("d-none")
    }
    this.attestationDivTarget.classList.add("d-none")
  }
  changesTableTargetConnected(){
    this.confirmButtonTarget.classList.remove("d-none")
    this.proceedButtonTarget.classList.add("d-none")
    if (this.hasVerificationDivTarget){
      this.verificationDivTarget.classList.remove("d-none")
    }
    this.attestationDivTarget.classList.remove("d-none")
  }

  triggerModal(e){
    e.preventDefault()
    this.changesLoaderTarget.classList.remove("d-none")
    this.changesCardTarget.classList.add("d-none")
    $(this.confirmationModalTarget).modal();
    if (this.contextValue == "procedure-step"){
      get(`/samples/${this.sampleIdValue}/shipped_sample_change_modal?context=${this.contextValue}&step_record_id=${this.stepRecordIdValue}&values=${JSON.stringify($(this.changeFormTarget).serializeArray())}`, { responseKind: 'turbo-stream' })
    } else {
      get(`/samples/${this.sampleIdValue}/shipped_sample_change_modal?context=${this.contextValue}&values=${JSON.stringify($(this.changeFormTarget).serializeArray())}`, { responseKind: 'turbo-stream' })
    }
  }

  toggleModal(e){
    $(this.confirmationModalTarget).modal('hide');
  }
  
  toggleSubmitButtons(){
    if (this.reasonSelectTarget.value == "" || (this.hasVerificationTarget && !this.verificationTarget.checked)){
      this.confirmButtonTarget.setAttribute('disabled', true)
      this.confirmButtonTarget.classList.add("disabled")
    } else {
      this.confirmButtonTarget.removeAttribute('disabled')
      this.confirmButtonTarget.classList.remove("disabled")
    }
  }

  manageOtherAliquots(){
    $("#shipped-submit").removeClass("d-none")
    $("#pending-submit").addClass("d-none")
    $("#other-aliquots-span").removeClass("d-none")
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="date-range-picker"
export default class extends Controller {
  static targets = [
    "fromInput",
    "toInput",
    "dropdownButton",
    "menu",
    "pickers",
    "fromPicker",
    "toPicker",
    "optionValue"
  ];

  static values = {
    startDate: String,
  };

  initialize() {
    this.dateFormatter = new Intl.DateTimeFormat(undefined, {
      month: "short",
      day: "numeric"
    });

    this.dateFormatterYear = new Intl.DateTimeFormat(undefined, {
      month: "short",
      day: "numeric",
      year: "numeric"
    });
  }

  connect() {
    $(this.menuTarget).on("click.bs.dropdown", (ev) => {
      ev.stopPropagation();
    });

    this.$fromPicker = $(this.fromPickerTarget);
    this.$toPicker = $(this.toPickerTarget);

    const pickers = [this.$fromPicker, this.$toPicker];

    this.$rangePicker = $(this.pickersTarget).datepicker({
      inputs: $(pickers),
      endDate: "0d",
      startDate: this.startDateValue.length > 0 ? this.startDateValue : undefined,
    });

    pickers.forEach((picker) => {
      picker.on("changeDate", () => {
        this.datesChanged();
      });
    });

    this.changeDates(this.fromInputTarget.value, this.toInputTarget.value);
  }

  disconnect() {
    this.$fromPicker?.datepicker("destroy");
    this.$toPicker?.datepicker("destroy");
  }

  pickedOption({ params }) {
    let start, stop;

    if (params.days) {
      start = `-${params.days}d`;
      stop = "0d";
    } else {
      start = params.start;
      stop = params.stop;
    }

    this.changeDates(start, stop);
  }

  changeDates(start, stop) {
    this.$fromPicker.datepicker("update", start);
    this.$toPicker.datepicker("update", stop);

    // For some reason, updating the dates in each picker doesn't update
    // the range selection. We can manually access the datepicker data
    // and force an update to work around it.
    this.$rangePicker.data("datepicker").updateDates();

    this.datesChanged();
  }

  datesChanged() {
    const startDate = this.$fromPicker.datepicker("getDate");
    const endDate = this.$toPicker.datepicker("getDate");

    this.fromInputTarget.value = startDate.toISOString().split('T')[0];
    this.toInputTarget.value = endDate.toISOString().split('T')[0];

    let stopLabel = this.dateFormatterYear.format(endDate);

    let startLabel;
    if (startDate.getYear() === endDate.getYear()) {
      startLabel = this.dateFormatter.format(startDate);
    } else {
      startLabel = this.dateFormatterYear.format(startDate);
    }

    const label = `${startLabel} - ${stopLabel}`;
    this.dropdownButtonTarget.replaceChildren(document.createTextNode(label));
  }
}
